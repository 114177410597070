import imgTakeshiMori from "../../../images/board-member/slide/mori-takeshi.jpg";
import imgYoshimiTakahashi from "../../../images/board-member/slide/takahashi-yoshimi.jpg";
import imgYutaroIshida from "../../../images/board-member/slide/ishida-yutaro.jpg";
import imgUenoKunitaka from "../../../images/board-member/slide/ueno-kunita.jpg";
import imgRyoheiSuga from "../../../images/board-member/slide/suga-ryuhei.jpg";
import imgYukiNakagawa from "../../../images/board-member/slide/nakagawa-yuki.jpg";
import imgToshihikoNishi from "../../../images/board-member/slide/nishi-toshihiko.jpg";
import imgHashimotoYuichi from "../../../images/board-member/slide/hashimoto-yuichi.jpg";
import imgItani from "../../../images/board-member/slide/sp_1913_itani.jpg";
import imgNakashima from "../../../images/board-member/slide/sp_1886_Nakashima.jpg";
import imgOkada from "../../../images/board-member/slide/koji-okada.jpg";

export const bordingMembers = [
  {
    key: "takeshi-mori",
    role: "代表取締役 CEO",
    name: "森 武司",
    image: imgTakeshiMori,
  },
  {
    key: "yoshimi-takahashi",
    role: "取締役",
    name: "高橋 良巳",
    image: imgYoshimiTakahashi,
  },
  {
    key: "yuki-nakagawa",
    role: "執行役員 COO",
    name: "中川 裕貴",
    image: imgYukiNakagawa,
  },
  {
    key: "yuichi-hashimoto",
    role: "執行役員 CRO",
    name: "橋本 雄一",
    image: imgHashimotoYuichi,
  },
  {
    key: "yutaro-ishida",
    role: "執行役員　CHRO",
    name: "石田 優太郎",
    image: imgYutaroIshida,
  },
  {
    key: "ryohei-suga",
    role: "執行役員 CMO",
    name: "菅 良平",
    image: imgRyoheiSuga,
  },
  {
    key: "toshihiko-nishi",
    role: "執行役員",
    name: "西 俊彦",
    image: imgToshihikoNishi,
  },
  {
    key: "naoto-nakashima",
    role: "CFO",
    name: "中嶋 尚人",
    image: imgNakashima,
  },
  {
    key: "kunitaka-ueno",
    role: "CIO",
    name: "植野 晋毅",
    image: imgUenoKunitaka,
  },
  {
    key: "yasuhiro-itani",
    role: "CCO",
    name: "井谷 育宏",
    image: imgItani,
  },
  {
    key: "koji-okada",
    role: "社長室室長",
    name: "岡田 幸士",
    image: imgOkada,
  },
];
