import React, {
  ReactNode,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import * as styles from "./pc/index.module.scss";
import * as spStyles from "./sp/index.module.scss";
import { bordingMembers } from "./board-member-image-graphql";

type BoardMemberSlidesCarouselProps = {
  members: any[];
};

const BoardMemberSlidesCarousel: React.FC<BoardMemberSlidesCarouselProps> = ({
  members,
}) => (
  <ul className={styles.BoardMemberSlides__carousel}>
    {members.map((member, index) => (
      <li
        className={styles.BoardMemberSlides__carouselLink}
        key={`boarding-member-${index}`}
      >
        <a href={`#${member.key}`}>
          <img
            src={member.image}
            className={styles.BoardMemberSlides__carouselMemberImage}
          />
          <div className={styles.BoardMemberSlides__carouselMember}>
            <h3 className={styles.BoardMemberSlides__carouselMemberRole}>
              {member.role}
            </h3>
            <p className={styles.BoardMemberSlides__carouselMemberName}>
              {member.name}
            </p>
          </div>
        </a>
      </li>
    ))}
  </ul>
);

// SPはスワイプを許容するのでコンポーネントを分ける
const BoardMemberSlidesCarouselSp: React.FC<BoardMemberSlidesCarouselProps> = ({
  members,
}) => {
  // const ref = useRef<HTMLUListElement | null>(null);

  // const handleTouchStart: TouchEventHandler = () => {
  //   const target: HTMLUListElement | null = ref.current;
  //   if (!target) {
  //     return;
  //   }

  //   target.style.overflowX = "";
  // };

  // let baseX = -1;
  // let baseTouchX = -1;
  // const handleTouchMove: TouchEventHandler = event => {
  //   const target: HTMLUListElement | null = ref.current;
  //   if (!target) {
  //     return;
  //   }

  //   if (baseX == -1) {
  //     baseX = target.getBoundingClientRect().left;
  //     baseTouchX = event.touches[0].screenX;
  //   }

  //   const distanceX = event.touches[0].screenX - baseTouchX;
  //   if (distanceX <= 0) {
  //     target.style.transform = `translateX(-${distanceX}px)`;
  //   }
  // };

  // const handleTouchEnd: TouchEventHandler = () => {
  //   const target: HTMLUListElement | null = ref.current;
  //   if (!target) {
  //     return;
  //   }

  //   target.style.overflowX = "hidden";
  // };

  return (
    <ul
      // ref={ref}
      className={spStyles.BoardMemberSlides__carousel}
      // onTouchStart={handleTouchStart}
      // onTouchMove={handleTouchMove}
      // onTouchEnd={handleTouchEnd}
    >
      {members.map((member, index) => (
        <li
          className={spStyles.BoardMemberSlides__carouselLink}
          key={`boarding-member-${index}`}
        >
          <a href={`#${member.key}`}>
            <img
              src={member.image}
              className={spStyles.BoardMemberSlides__carouselMemberImage}
            />
            <div className={spStyles.BoardMemberSlides__carouselMember}>
              <h3 className={spStyles.BoardMemberSlides__carouselMemberRole}>
                {member.role}
              </h3>
              <p className={spStyles.BoardMemberSlides__carouselMemberName}>
                {member.name}
              </p>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};

export const BoardMemberSlides = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [touchCount, setTouchCount] = useState(0);
  // SPはタッチできるので、とりあえず10ループで応急処置
  const children: ReactNode[] = [
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
    <BoardMemberSlidesCarouselSp members={bordingMembers} />,
  ];

  const handleTouchStart: TouchEventHandler = () => {
    const target: HTMLDivElement | null = ref.current;
    if (!target) {
      return;
    }

    target.style.overflow = "auto";
  };

  const handleTouchEnd: TouchEventHandler = () => {
    const target: HTMLDivElement | null = ref.current;
    if (!target) {
      return;
    }

    target.style.overflow = "hidden";
    children.push(<BoardMemberSlidesCarouselSp members={bordingMembers} />);
    setTouchCount(touchCount + 1);
  };

  useEffect(() => {
    console.log("あああ");
  }, [touchCount]);

  return (
    <>
      <div className={styles.BoardMemberSlides}>
        {/* ウィンドウ幅を -100% 左に流すアニメーションなので、途切れないようにカルーセル自体を二つ用意する */}
        <BoardMemberSlidesCarousel members={bordingMembers} />
        <BoardMemberSlidesCarousel members={bordingMembers} />
      </div>
      <div
        ref={ref}
        className={spStyles.BoardMemberSlides}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
    </>
  );
};
