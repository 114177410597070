import React from "react";
export const ArrowUp = ({ width = 1, color = "#707070" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.217"
    height="81.121"
    viewBox="0 0 8.217 81.121"
  >
    <path
      id="arrowUp"
      data-name="arrowUp"
      d="M-11206-15669.117v-80l7.383,6.629"
      transform="translate(11206.501 15750.238)"
      fill="none"
      stroke={color}
      stroke-width={width}
    />
  </svg>
);
