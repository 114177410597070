// extracted by mini-css-extract-plugin
export var managementMember = "r_g9";
export var absolute = "r_dK";
export var title = "r_c";
export var cover = "r_dS";
export var coverBg = "r_hb";
export var coverCenter = "r_b";
export var lgOver = "r_hc";
export var lgUnder = "r_hd";
export var memberContainer = "r_hf";
export var director = "r_hg";
export var director2ColumnContainer = "r_hh";
export var imageContainer = "r_hj";
export var flex1 = "r_hk";
export var imageContainerSmall = "r_hl";
export var memberImg = "r_hm";
export var imageContainerSmall__bottomLeft = "r_hn";
export var imageContainerSmall__bottomCenter = "r_hp";
export var imageContainerSmall__bottomRight = "r_hq";
export var nameBox = "r_hr";
export var director4ColumnContainer = "r_hs";
export var director3ColumnContainer = "r_ht";
export var operatingOfficer = "r_hv";
export var box1 = "r_hw";
export var back = "r_hx";
export var linkMargin = "r_g1";
export var coverMember = "r_hy";
export var name = "r_hz";
export var biography = "r_hB";
export var colName = "r_hC";
export var colBio = "r_hD";