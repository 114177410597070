import React, { FunctionComponent, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/management-member.module.scss";
import { BoardMemberSlides } from "../../organisms/company/board-member/BoardMemberSlides";
import { Link } from "gatsby";
import MoriCover from "../../images/board-member/_kpo1245_re.jpg";
import TakahashiCover from "../../images/board-member/mean7436_takahashi.jpg";
import NakagawaCover from "../../images/board-member/_kpo1864_nakagawa.jpg";
import HashimotoCover from "../../images/board-member/_kpo2117_hashimoto.jpg";
import IshidaCover from "../../images/board-member/_kpo1783_ishida.jpg";
import NishiCover from "../../images/board-member/_kpo2686_nishi.jpg";
import SugaCover from "../../images/board-member/_kpo2793_suga.jpg";
import UenoCover from "../../images/board-member/_kpo1223_ueno.jpg";
import ItaniCover from "../../images/board-member/sp_1967_itani.jpg";
import NakashimaCover from "../../images/board-member/sp_1988_nakashima.jpg";
import OkudaCover from "../../images/board-member/okada.jpeg";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/top/company/board-member.jpg";
import { ArrowUp } from "../../atoms/graphics/ArrowUp";

const BackToOfficers: FunctionComponent = ({}) => (
  <div className={styles.back}>
    <Link to="#officer">
      <ArrowUp />
      <span>役員一覧へ戻る</span>
    </Link>
  </div>
);

export default ({ location: pathname }) => {
  const [isWhite, setIsWhite] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsWhite(true);
      } else {
        setIsWhite(false);
      }
    });

    const targets = document.querySelectorAll(
      "[data-change-header-color-point]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout isWhite={isWhite} className={styles.managementMember}>
      <SEO
        pathname={pathname}
        title="役員紹介 | Board member"
        description="Board member・代表取締役CEO森武司・取締役高橋良巳・執行役員COO中川裕貴・執行役員CRO橋本雄一・執行役員CHRO石田優太郎・執行役員西俊彦・執行役員CMO菅良平・CIO植野晋毅."
        image={seoImage}
      />
      <div className={styles.absolute}>
        <div className={styles.coverCenter}>
          <h2 className={styles.title}>
            Board member
            <br />
            <span>役員紹介</span>
          </h2>
        </div>
        <BoardMemberSlides />
      </div>

      {/* Intersection Observer 用にヘッダーのカラーを変えるポイントを仕込む */}
      <div data-change-header-color-point="data-change-header-color-point" />

      <div id="officer">
        <div className={styles.lgOver}>
          <div className={styles.memberContainer}>
            <div className={styles.director}>
              <div>
                <div className={styles.director4ColumnContainer}>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#mori-takeshi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1245_re.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>代表取締役 CEO</h3>
                        <span>森 武司</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#takahashi-yoshimi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/mean7436_takahashi.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>取締役</h3>
                        <span>高橋 良巳</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yuki-nakagawa">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1864_nakagawa.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 COO</h3>
                        <span>中川 裕貴</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yuichi-hashimoto">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2117_hashimoto.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CRO</h3>
                        <span>橋本 雄一</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yutaro-ishida">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1783_ishida.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CHRO</h3>
                        <span>石田 優太郎</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#ryohei-suga">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2793_suga.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CMO</h3>
                        <span>菅 良平</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#toshihiko-nishi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2686_nishi.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員</h3>
                        <span>西 俊彦</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#naoto-nakashima">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/sp_1988_nakashima.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CFO</h3>
                        <span>中嶋 尚人</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall__bottomLeft}`}
                  >
                    <Link to="#kunitaka-ueno">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1223_ueno.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CIO</h3>
                        <span>植野 晋毅</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall__bottomCenter}`}
                  >
                    <Link to="#yasuhiro-itani">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/sp_1967_itani.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CCO</h3>
                        <span>井谷 育宏</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall__bottomRight}`}
                  >
                    <Link to="#koji-okada">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/okada.jpeg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>社長室室長</h3>
                        <span>岡田 幸士</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.lgUnder}>
          <div className={styles.memberContainer}>
            <div className={styles.director}>
              <div>
                <div className={styles.director4ColumnContainer}>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#mori-takeshi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1245_re.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>代表取締役 CEO</h3>
                        <span>森 武司</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#takahashi-yoshimi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/mean7436_takahashi.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>取締役</h3>
                        <span>高橋 良巳</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yuki-nakagawa">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1864_nakagawa.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 COO</h3>
                        <span>中川 裕貴</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yuichi-hashimoto">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2117_hashimoto.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CRO</h3>
                        <span>橋本 雄一</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#yutaro-ishida">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1783_ishida.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CHRO</h3>
                        <span>石田 優太郎</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#ryohei-suga">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2793_suga.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員 CMO</h3>
                        <span>菅 良平</span>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.imageContainerSmall}>
                    <Link to="#toshihiko-nishi">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo2686_nishi.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>執行役員</h3>
                        <span>西 俊彦</span>
                      </div>
                    </Link>
                  </div>

                  <div className={styles.imageContainerSmall}>
                    <Link to="#naoto-nakashima">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/sp_1988_nakashima.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CFO</h3>
                        <span>中嶋 尚人</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall}--bottomLeft`}
                  >
                    <Link to="#kunitaka-ueno">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/_kpo1223_ueno.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CIO</h3>
                        <span>植野 晋毅</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall}--bottomCenter`}
                  >
                    <Link to="#yasuhiro-itani">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/sp_1967_itani.jpg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>CCO</h3>
                        <span>井谷 育宏</span>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`${styles.imageContainerSmall} ${styles.imageContainerSmall}--bottomRight`}
                  >
                    <Link to="#koji-okada">
                      <StaticImage
                        className={styles.memberImg}
                        src="../../images/board-member/okada.jpeg"
                        alt=""
                      />
                      <div className={styles.nameBox}>
                        <h3>社長室室長</h3>
                        <span>岡田 幸士</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a className={styles.linkMargin} id="mori-takeshi" />
        <div className={styles.coverMember}>
          <img src={MoriCover} alt="森 武司" className={styles.coverBg} />
          <div className={styles.name}>
            <h2>森 武司</h2>
            <h3>代表取締役 CEO</h3>
          </div>
        </div>

        <div className={styles.biography}>
          <div className={styles.colName}>
            <h3>森 武司</h3>
            <h2>Takeshi Mori</h2>
            <h4 className="sp-only">代表取締役 CEO</h4>
          </div>
          <div className={styles.colBio}>
            <p>
              吉本興業NSC18期卒。
              <br />
              芸人時代に同期最速でオーディションを勝ち上がり決勝では野性爆弾と対戦するも30対0の大敗北。
              <br />
              それを機にお笑い芸人を引退。
              <br />
              会社員を経て、2005年わくわくエッサ有限会社（現Suprieve
              Holdings株式会社）を設立。
              <br />
              創業から全ての新規事業を企画立案、全事業の戦略、幹部人材の採用を主に担当。
              <br />
              特に新規事業立案をライフワークにしておりコスメ事業、人材事業、広告事業、福祉事業、ART事業など関連のない異業種を30事業以上立ち上げ、15事業を黒字化。
              <br />
              30万人の交流コミュニティを創業しバイアウト。
              <br />
              グループ年商100億、創業以来16年連続で増収増益を達成。
              <br />
              Financial Times アジア急成長企業ランキング
              アジア103位、日本14位、西日本1位などSuprieve
              Holdings株式会社をCEOとして牽引。
            </p>
          </div>
        </div>
      </div>

      <BackToOfficers />

      <div>
        <a className={styles.linkMargin} id="takahashi-yoshimi" />
        <div className={styles.coverMember}>
          <img
            src={TakahashiCover}
            alt="高橋 良巳"
            className={styles.coverBg}
          />
          <div className={styles.name}>
            <h2>高橋 良巳</h2>
            <h3>取締役</h3>
          </div>
        </div>

        <div className={styles.biography}>
          <div className={styles.colName}>
            <h3>高橋 良巳</h3>
            <h2>Yoshimi Takahashi</h2>
            <h4 className="sp-only">取締役</h4>
          </div>
          <div className={styles.colBio}>
            <p>
              早稲田大学法学部卒。韓国発のオンライン証券会社であるE
              Trade証券を設立経営しIPO達成、破綻直前の韓国最大貯蓄銀行を買収し再生に成功、300社を超える韓国ベンチャー企業へ投資するなど多くのプロジェクト実績を持つ。
              <br />
              韓国第1号のVC（現SBIインベストメント）のCEO、ソフトバンク・ファイナンス・コリア株式会社代表取締役、SBIコリア・ホールディングス株式会社代表取締役を歴任し、2019年4月より当社取締役として参画。2022年1月よりGLOBAL
              FRONT INVESTMENT株式会社代表取締役に就任。
            </p>
          </div>
        </div>

        <BackToOfficers />

        <div>
          <a className={styles.linkMargin} id="yuki-nakagawa" />
          <div className={styles.coverMember}>
            <img
              src={NakagawaCover}
              alt="中川 裕貴"
              className={styles.coverBg}
            />
            <div className={styles.name}>
              <h2>中川 裕貴</h2>
              <h3>執行役員 COO</h3>
            </div>
          </div>

          <div className={styles.biography}>
            <div className={styles.colName}>
              <h3>中川 裕貴</h3>
              <h2>Yuki Nakagawa</h2>
              <h4 className="sp-only">執行役員 COO</h4>
            </div>
            <div className={styles.colBio}>
              <p>
                神戸大学経営学部卒。大和証券グループ、学習塾経営を経て、デロイトトーマツコンサルティング合同会社で人事コンサルタントとして人事制度設計や組織再編時の人事課題解決を主導。
                <br />
                その後、コンサルタントとして独立し、電力会社の人事制度設計支援、大手ビール会社の人事戦略立案支援、等PJ実績あり。
                <br />
                2018年8月よりコンサルタントとしてSuprieve
                Holdings株式会社の人事制度設計・経営戦略立案を支援。
                その後、2020年7月よりSuprieve
                Holdings株式会社COOとして経営戦略立案、幹部人材採用、新規事業・新規PJを推進しつつ、コンサルティング事業、美容医療事業、金融事業を管掌。
              </p>
            </div>
          </div>

          <BackToOfficers />

          <div>
            <a className={styles.linkMargin} id="yuichi-hashimoto" />
            <div className={styles.coverMember}>
              <img
                src={HashimotoCover}
                alt="橋本 雄一"
                className={styles.coverBg}
              />
              <div className={styles.name}>
                <h2>橋本 雄一</h2>
                <h3>執行役員 CRO</h3>
              </div>
            </div>

            <div className={styles.biography}>
              <div className={styles.colName}>
                <h3>橋本 雄一</h3>
                <h2>Yuichi Hashimoto</h2>
                <h4 className="sp-only">執行役員 CRO</h4>
              </div>
              <div className={styles.colBio}>
                <p>
                  人材派遣会社の取締役を務めていた頃、Suprieve代表の森からのヘッドハンティングを受ける。
                  Suprieve株式会社の執行役員として入社し、人材事業部を設立。
                  <br />
                  従業員約1,000名、年商35億規模の事業へ成長させる。その後、多数の新規事業を立ち上げ、代表や役員を兼任する。
                  現在のメイン管掌事業は、有名人やYouTuberを起用した企業プロモーション事業および、有名人やYouTuberの海外進出を手助けするインフルエンサー輸出事業である。
                  <br />
                  政財界に広いネットワークを持ち、多国籍企業の代表や役員に、大きく飛躍するベンチャー企業を紹介するといった、ベンチャー支援事業も手掛けている。
                </p>
              </div>
            </div>
          </div>

          <BackToOfficers />

          <div>
            <a className={styles.linkMargin} id="yutaro-ishida" />
            <div className={styles.coverMember}>
              <img
                src={IshidaCover}
                alt="石田 優太郎"
                className={styles.coverBg}
              />
              <div className={styles.name}>
                <h2>石田 優太郎</h2>
                <h3>執行役員 CHRO</h3>
              </div>
            </div>

            <div className={styles.biography}>
              <div className={styles.colName}>
                <h3>石田 優太郎</h3>
                <h2>Yutaro Ishida</h2>
                <h4 className="sp-only">執行役員 CHRO</h4>
              </div>
              <div className={styles.colBio}>
                <p>
                  専修大学商学部卒。株式会社グリムスに入社。
                  <br />
                  法人営業を経験した後、2011年株式会社ウィルオブ・ワークに入社。
                  <br />
                  コールセンター派遣部門にて、法人営業・スタッフ管理に従事。年間ベストコーディネーター賞を受賞。
                  <br />
                  同社での経験をもとに2016年 Suprieve Holdings株式会社
                  人材事業の立ち上げメンバーとして参画。
                  <br />
                  正規雇用の派遣事業を一気に拡大。事業立ち上げ2年目で黒字化。7年目で全国5拠点・従業員1,560名・年商44億へ成長させる。
                  <br />
                  2021年2月Suprieve
                  Holdings株式会社より人材事業を分社化、Evand株式会社代表取締役に就任。
                </p>
              </div>
            </div>
          </div>

          <BackToOfficers />

          <div>
            <a className={styles.linkMargin} id="ryohei-suga" />
            <div className={styles.coverMember}>
              <img src={SugaCover} alt="菅 良平" className={styles.coverBg} />
              <div className={styles.name}>
                <h2>菅 良平</h2>
                <h3>執行役員 CMO</h3>
              </div>
            </div>

            <div className={styles.biography}>
              <div className={styles.colName}>
                <h3>菅 良平</h3>
                <h2>Ryohei Suga</h2>
                <h4 className="sp-only">執行役員 CMO</h4>
              </div>
              <div className={styles.colBio}>
                <p>
                  独学でWEB広告の勉強に励む。数多くの広告セミナーに参加し、コミュニティを広げると、自分の力で成果を出すことに達成感を覚え個人事業主として会社を立ち上げる。その後たった一人で月商3000万円以上を達成。
                  <br />
                  その後Suprieveからヘッドハンティングを受け、執行役員として入社。広告事業部を任される。Yahoo、Google、Line、Facebook、Instagram、Twitter等のプラットフォームでの広告運用を得意とする。
                  <br />
                  組織の一員として良い時も悪い時も共感しあえる仲間と日本一のWEB集客を得意とした集団を作るため日々奮闘中。2021年
                  SBI証券の口座開設数1位を獲得。
                </p>
              </div>
            </div>
          </div>

          <BackToOfficers />

          <div>
            <a className={styles.linkMargin} id="toshihiko-nishi" />
            <div className={styles.coverMember}>
              <img src={NishiCover} alt="西 俊彦" className={styles.coverBg} />
              <div className={styles.name}>
                <h2>西 俊彦</h2>
                <h3>執行役員</h3>
              </div>
            </div>

            <div className={styles.biography}>
              <div className={styles.colName}>
                <h3>西 俊彦</h3>
                <h2>Toshihiko Nishi</h2>
                <h4 className="sp-only">執行役員</h4>
              </div>
              <div className={styles.colBio}>
                <p>
                  吉本興業NSC18期生。お笑い芸人活動引退後、Suprieve
                  Holdings株式会社のEC事業部に入社。
                  <br />
                  amazonを中心としたweb物販事業を運用。販売戦略設計、仕入れ計画、ECツール構築管理などを総括する。2016年にはコスメや健康食品を中心とした、オリジナルブランドの企画とweb販売事業を立上げ。総括責任者としてECモールの戦略設計、CRM、組織強化などを図り、初動3年間で32億円以上の売上を達成。2018年amazon年間カテゴリーランキング1位獲得。2019年楽天ショップ・オブ・ザ・イヤー獲得。2021年楽天ショップオブ・ザ・エリア、amazon売れ筋15ブランド獲得。
                  <br />
                  また社外でも多くのEC/D2C運営企業のコンサルタントを務める。
                </p>
              </div>
            </div>
          </div>

          <BackToOfficers />

          <div>
            <a className={styles.linkMargin} id="naoto-nakashima" />
            <div className={styles.coverMember}>
              <img
                src={NakashimaCover}
                alt="中嶋 尚人"
                className={styles.coverBg}
              />
              <div className={styles.name}>
                <h2>中嶋 尚人</h2>
                <h3>CFO</h3>
              </div>
            </div>

            <div className={styles.biography}>
              <div className={styles.colName}>
                <h3>中嶋 尚人</h3>
                <h2>Naoto Nakashima</h2>
                <h4 className="sp-only">CFO</h4>
              </div>
              <div className={styles.colBio}>
                <p>
                  京都大学工学部卒。株式会社京都銀行に入行。法人営業として、上場企業から中堅・中小・零細企業まで、様々な規模・業種の企業を担当。
                  <br />
                  約10年間で累計500社超の企業を担当し、事業性融資を中心に、スタートアップ支援やM&A・事業承継支援、預かり資産運用支援、経営再建支援や廃業支援、債権回収に至るまで幅広い実務を経験。
                  <br />
                  2021年3月にZENHのモデルとして友情出演したことがきっかけとなり、2021年7月にCFOとしてSuprieve
                  Holdings株式会社に参画。
                </p>
              </div>
            </div>

            <BackToOfficers />

            <div>
              <a className={styles.linkMargin} id="kunitaka-ueno" />
              <div className={styles.coverMember}>
                <img
                  src={UenoCover}
                  alt="植野 晋毅"
                  className={styles.coverBg}
                />
                <div className={styles.name}>
                  <h2>植野 晋毅</h2>
                  <h3>CIO</h3>
                </div>
              </div>

              <div className={styles.biography}>
                <div className={styles.colName}>
                  <h3>植野 晋毅</h3>
                  <h2>Kunitaka Ueno</h2>
                  <h4 className="sp-only">CIO</h4>
                </div>
                <div className={styles.colBio}>
                  <p>
                    米カリフォルニア大学サンディエゴ校卒。英ロンドン・スクール・オブ・エコノミクス（LSE）にて金融経済学修士号を取得。モルガン・スタンレー証券投資銀行本部に入社し、クロスボーダーM&A案件を担当。
                    <br />
                    その後は運用の世界に軸足を移し、債券ファンドPIMCOではポートフォリオマネジャーとして約3000億円の運用を担当。2018年に独立してシンガポールに拠点を移し、アジアの富裕層向けに株式ポートフォリオの運用を統括。また様々なフェーズの事業会社の顧問として、事業計画の立案や資金調達支援など、多角的に活動。
                    <br />
                    直近では韓国を生産拠点とする美容医療メーカーのMBOとそれに伴う資金調達を実現。
                    <br />
                    2020年12月 投資事業責任者としてSuprieve
                    Holdings株式会社に参画。
                  </p>
                </div>
              </div>
            </div>

            <BackToOfficers />

            <div>
              <a className={styles.linkMargin} id="yasuhiro-itani" />
              <div className={styles.coverMember}>
                <img
                  src={ItaniCover}
                  alt="井谷 育宏"
                  className={styles.coverBg}
                />
                <div className={styles.name}>
                  <h2>井谷 育宏</h2>
                  <h3>CCO</h3>
                </div>
              </div>

              <div className={styles.biography}>
                <div className={styles.colName}>
                  <h3>井谷 育宏</h3>
                  <h2>Yasuhiro Itani</h2>
                  <h4 className="sp-only">CCO</h4>
                </div>
                <div className={styles.colBio}>
                  <p>
                    大阪芸術大学デザイン学科卒。スポーツシューズ、化粧品ディスプレイ・店舗内装等の経験を経て、2008年9月株式会社&Aを創業。
                    <br />
                    プロダクトデザインを中心に日用家庭用品、美容機器、電動バイクに至るまで様々な商品開発を手掛け、2015年FLOATスリムトイレクリーナー、2021年電動バイクglafit02でGOOD
                    DESIGN受賞。
                    <br />
                    現在では総合力を活かし、商品開発、ブランディング、パッケージ、WEB、空間等、トータルデザインで高いクリエイティブを発揮。
                    <br />
                    2021年2月Suprieve Holdings株式会社CCO就任。
                  </p>
                </div>
              </div>
            </div>

            <BackToOfficers />

            <div>
              <a className={styles.linkMargin} id="koji-okada" />
              <div className={styles.coverMember}>
                <img
                  src={OkudaCover}
                  alt="岡田 幸士"
                  className={styles.coverBg}
                />
                <div className={styles.name}>
                  <h2>岡田 幸士</h2>
                  <h3>社長室室長</h3>
                </div>
              </div>

              <div className={styles.biography}>
                <div className={styles.colName}>
                  <h3>岡田 幸士</h3>
                  <h2>Koji Okada</h2>
                  <h4 className="sp-only">社長室室長</h4>
                </div>
                <div className={styles.colBio}>
                  <p>
                    神戸大学経営学部卒。日本マクドナルドの人事部で人事戦略・制度策定・労政等に従事。
                    <br />
                    デロイトトーマツコンサルティング合同会社に転職した後は組織・人事コンサルタントとして累計売上60兆円を超える会社を支援し、
                    トップ3%の評価受ける。
                    <br />
                    その後独立して、対大企業・中堅企業のみならず、IPO前準備中や企業再生（民事再生・会社更生中）など様々なフェーズの企業に対してコンサルティングを実施。
                    <br />
                    2020年にSuprieve
                    Holdings株式会社の社長室室長として参画しつつ、Suprieve
                    Consultingの執行役員も兼務。
                  </p>
                </div>
              </div>
            </div>

            <BackToOfficers />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
